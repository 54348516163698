import { createConsumer } from "@rails/actioncable";

import { core as log } from "./logger";

const CHANNEL_NAME = "ChatChannel";

const getConsumerPath = (session) => {
  const url = new URL(import.meta.env.APPLY_REALTIME_URL);
  url.searchParams.set("token", session.token);
  url.searchParams.set("sid", session.sid);
  return url.toString();
};

export const createSubscription = (session, handlers) => {
  const consumer = createConsumer(getConsumerPath(session));
  return consumer.subscriptions.create(
    { channel: CHANNEL_NAME },
    {
      initialized() {
        log("initialized subscription");
        handlers.initialized?.();
      },
      rejected() {
        log("subscription rejected");
        handlers.rejected?.();
      },
      connected(data) {
        log("subscription connected", data);
        handlers.connected?.(data);
      },
      disconnected(data) {
        log("subscription disconnected", data);
        handlers.disconnected?.(data);
      },
      received(data) {
        log("received", data);
        handlers.received?.(data);
      },
    },
  );
};
