import clsx from "clsx";

import { useApi } from "../contexts/ApiContext";

const CircularProgress = ({
  badgeColor = "stroke-primary",
  iconFillColor = "fill-primary",
  progressPercentage = 100,
  size = "md",
}) => {
  const sizes = {
    sm: { svgSize: 40, radius: 18, iconSize: 16 },
    md: { svgSize: 60, radius: 28, iconSize: 26 },
    lg: { svgSize: 85, radius: 35, iconSize: 36 },
  };

  const { svgSize, radius, iconSize } = sizes[size];
  const circumference = 2 * Math.PI * radius;
  const offset = circumference - (progressPercentage / 100) * circumference;

  return (
    <div className="relative" style={{ width: svgSize, height: svgSize }}>
      <svg width={svgSize} height={svgSize} className="block">
        <circle
          cx={svgSize / 2}
          cy={svgSize / 2}
          r={radius}
          fill="none"
          stroke="#e5e7eb"
          strokeWidth={svgSize * 0.07}
        />
        <circle
          cx={svgSize / 2}
          cy={svgSize / 2}
          r={radius}
          fill="none"
          strokeWidth={svgSize * 0.07}
          strokeDasharray={circumference}
          strokeDashoffset={-offset}
          strokeLinecap="round"
          className={`transition-all duration-300 ease-in-out ${badgeColor}`}
          transform={`rotate(-90 ${svgSize / 2} ${svgSize / 2})`}
        />
      </svg>

      <div
        className="flex items-center justify-center absolute inset-0 m-auto"
        style={{ width: iconSize, height: iconSize, borderRadius: "50%" }}
      >
        <svg
          width={iconSize}
          height={iconSize}
          viewBox="0 0 26 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.6876 0.893072C13.302 0.52783 12.698 0.52783 12.3124 0.893072C9.62336 3.4397 5.99499 5.00014 2 5.00014C1.93644 5.00014 1.87298 4.99975 1.80961 4.99896C1.37312 4.99354 0.983559 5.27187 0.84722 5.68655C0.297164 7.35957 0 9.14618 0 11.0002C0 18.9229 5.41874 25.5774 12.7507 27.4646C12.9143 27.5067 13.0858 27.5067 13.2493 27.4646C20.5813 25.5774 26 18.9229 26 11.0002C26 9.14618 25.7028 7.35957 25.1528 5.68655C25.0164 5.27187 24.6269 4.99354 24.1904 4.99896C24.127 4.99975 24.0636 5.00014 24 5.00014C20.005 5.00014 16.3766 3.4397 13.6876 0.893072ZM17.8137 11.5812C18.1347 11.1318 18.0307 10.5073 17.5812 10.1863C17.1318 9.86526 16.5073 9.96935 16.1863 10.4188L11.8724 16.4582L9.70711 14.2929C9.31658 13.9024 8.68342 13.9024 8.29289 14.2929C7.90237 14.6834 7.90237 15.3166 8.29289 15.7071L11.2929 18.7071C11.5007 18.915 11.7895 19.0208 12.0825 18.9966C12.3754 18.9723 12.6429 18.8204 12.8137 18.5812L17.8137 11.5812Z"
            className={iconFillColor}
          />
        </svg>
      </div>
    </div>
  );
};

export const ProgressBadge = ({ badgeColor, radius = 24, size }) => (
  <div className="flex flex-col items-center">
    <CircularProgress badgeColor={badgeColor} radius={radius} size={size} />
  </div>
);

export const ProgressIndicator = ({
  currentStep,
  index,
  totalSteps,
  containerStyle,
  titleStyle,
  size,
  titleContainerStyle,
}) => {
  const progressPercentage = ((index + 1) / totalSteps) * 100;
  const baseUrl = import.meta.env.APPLY_STANDALONE_URL;
  const { api } = useApi();
  const eeocUrl = new URL(`/eeoc?token=${api?.token}`, baseUrl);

  return (
    <div className={clsx("flex items-center", containerStyle)}>
      <div className="flex flex-col items-center">
        <CircularProgress
          radius={24}
          progressPercentage={progressPercentage}
          size={size}
        />
        <span className="text-xs">{`${index + 1} of ${totalSteps}`}</span>
      </div>
      <div className={clsx("ml-3", titleContainerStyle)}>
        <h3 className={clsx("text-desktopNeutralDark", titleStyle)}>
          {currentStep.instructions}
        </h3>
        <a
          href={eeocUrl}
          target="_blank"
          rel="noopener noreferrer"
          className="text-xs font-medium text-primary mt-1 cursor-pointer"
        >
          Why are we asking for this?
        </a>
      </div>
    </div>
  );
};

export default ProgressIndicator;
