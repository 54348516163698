import * as Dialog from "@radix-ui/react-dialog";
import clsx from "clsx";
import { useState } from "react";

import { useApi } from "../contexts/ApiContext";
import { useConfig } from "../contexts/ConfigContext";

/**
 * @param {object} props
 * @param {object} props.config
 */
const TermsContent = ({ config }) => (
  <>
    <span>{`By using this assistant, you agree to the ${config.company_name} `}</span>
    <a
      href={config.privacy_policy_url}
      className="text-blue-700 underline"
      target="_blank"
      rel="noreferrer"
    >
      privacy policy
    </a>
    <span>{" and "}</span>
    <a
      href={config.terms_of_service_url}
      className="text-blue-700 underline"
      target="_blank"
      rel="noreferrer"
    >
      terms of use
    </a>
    <span>{"."}</span>
  </>
);

const TermsLoader = () => (
  <div role="status" className="space-y-2.5 animate-pulse max-w-lg">
    <div className="flex items-center w-full">
      <div className="h-2.5 bg-gray-200 rounded-full w-32"></div>
      <div className="h-2.5 ms-2 bg-gray-300 rounded-full w-24"></div>
      <div className="h-2.5 ms-2 bg-gray-300 rounded-full w-full"></div>
    </div>
    <div className="flex items-center w-full max-w-[480px]">
      <div className="h-2.5 bg-gray-200 rounded-full w-full"></div>
      <div className="h-2.5 ms-2 bg-gray-300 rounded-full w-full"></div>
      <div className="h-2.5 ms-2 bg-gray-300 rounded-full w-24"></div>
    </div>
    <div className="flex items-center w-full max-w-[360px]">
      <div className="h-2.5 bg-gray-300 rounded-full w-full"></div>
      <div className="h-2.5 ms-2 bg-gray-200 rounded-full w-80"></div>
      <div className="h-2.5 ms-2 bg-gray-300 rounded-full w-full"></div>
    </div>
    <span className="sr-only">Loading...</span>
  </div>
);

/**
 * @param {object} props
 * @param {boolean} props.termsAccepted
 * @param {() => void} props.onAccept
 * @param {HTMLElement} props.container
 * @param {boolean} props.isDrawer
 */
function TermsDialog({ termsAccepted, onAccept, container, isDrawer }) {
  const open = !termsAccepted;
  const { config } = useConfig();
  const { api } = useApi();

  const [loading, setLoading] = useState(false);

  const acceptTerms = () => {
    setLoading(true);
    api.acceptTerms().then(() => {
      onAccept();
    });
  };

  return (
    <Dialog.Root modal={false} open={open} onOpenChange={onAccept}>
      {open && (
        <div className="absolute inset-0 z-10 pointer-events-none bg-black/25"></div>
      )}
      <Dialog.Portal container={container}>
        <Dialog.Content
          className={clsx(
            "absolute z-20 top-[50%] border left-[50%] max-h-[85vh] w-[90vw] translate-x-[-50%] translate-y-[-50%] rounded-lg bg-white p-6 shadow-xl focus:outline-none",
            isDrawer ? "max-w-[325px]" : "max-w-[450px]",
          )}
          onInteractOutside={(e) => {
            // non-modal dialog, so clicking outside should not close it
            e.preventDefault();
          }}
          onEscapeKeyDown={(e) => {
            // non-modal dialog, so pressing escape should not close it
            e.preventDefault();
          }}
        >
          <Dialog.Title className="text-gray-900 m-0 text-[17px] font-medium">
            Terms and Conditions
          </Dialog.Title>
          <Dialog.Description className="text-gray-700 mt-4 text-[15px] leading-normal">
            {config ? <TermsContent config={config} /> : <TermsLoader />}
          </Dialog.Description>
          <div className="flex justify-end mt-8">
            <button
              className="px-3 py-2 text-white transition-colors rounded-lg disabled:bg-gray-400 bg-primary/90 end-6 hover:bg-primary focus:ring-2 focus:outline-none focus:ring-primary/40"
              disabled={!config || loading}
              onClick={acceptTerms}
            >
              Accept
            </button>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}

export default TermsDialog;
