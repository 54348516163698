import * as Dialog from "@radix-ui/react-dialog";
import clsx from "clsx";

import { useApi } from "../contexts/ApiContext";

// eslint-disable-next-line no-undef
const REVISION = __REVISION__.slice(0, 7) || "unknown";

function DevtoolsDialog({
  isOpen,
  onClose,
  container,
  isDrawer,
  handleClearSession,
}) {
  const { api } = useApi();

  const clipboardStr = `Grayscale AI Assistant (rev. ${REVISION})
Environment: ${import.meta.env.APPLY_ENV}
SID: ${api?.sid || "None"}
API Url: ${import.meta.env.APPLY_API_URL}
Realtime Url: ${import.meta.env.APPLY_REALTIME_URL}`;

  const contentStr = `Environment: ${import.meta.env.APPLY_ENV}
SID: ${api?.sid || "None"}
API Url: ${import.meta.env.APPLY_API_URL}
Realtime Url: ${import.meta.env.APPLY_REALTIME_URL}`;

  return (
    <Dialog.Root open={isOpen} onOpenChange={onClose}>
      {isOpen && (
        <div className="absolute inset-0 z-10 pointer-events-none bg-black/25"></div>
      )}
      <Dialog.Portal container={container}>
        <Dialog.Content
          className={clsx(
            "absolute z-20 top-[50%] border left-[50%] max-h-[85vh] w-[90vw] translate-x-[-50%] translate-y-[-50%] rounded-lg bg-white p-6 shadow-xl focus:outline-none",
            isDrawer ? "max-w-[325px]" : "max-w-[450px]",
          )}
        >
          <Dialog.Title className="text-gray-900 text-[17px] font-medium">
            Grayscale AI Assistant
          </Dialog.Title>
          <Dialog.Description className="text-gray-700 mt-0.5 text-[15px] leading-normal">
            rev. {REVISION}
          </Dialog.Description>

          <div className="mt-4">
            <pre className="p-2 overflow-x-auto text-gray-900 bg-gray-100 rounded-md">
              {contentStr}
            </pre>
          </div>

          <div className="flex justify-end gap-2 mt-8">
            <button
              className="px-3 py-2 text-white transition-colors rounded-lg bg-gray-500/90 end-6 hover:bg-gray-500 focus:ring-2 focus:outline-none focus:ring-gray-500/40"
              onClick={() => {
                navigator.clipboard.writeText(clipboardStr);
              }}
            >
              Copy details
            </button>
            <button
              className="px-3 py-2 text-white transition-colors rounded-lg bg-red-500/90 end-6 hover:bg-red focus:ring-2 focus:outline-none focus:ring-red-500/40"
              onClick={handleClearSession}
            >
              Clear session
            </button>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}

export default DevtoolsDialog;
