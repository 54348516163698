export default function FooterIcon({ className }) {
  return (
    <svg
      viewBox="0 0 17 16"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <title>Grayscale logo</title>
      <desc>A stylized letter G on a muted circular background</desc>
      <g clipPath="url(#clip0_238_31008)">
        <g clipPath="url(#clip1_238_31008)">
          <path
            d="M0.5 4.41379C0.5 1.97612 2.47612 0 4.91379 0H12.6379C15.0756 0 17.0517 1.97612 17.0517 4.41379V12.1379C17.0517 14.5756 15.0756 16.5517 12.6379 16.5517H4.91379C2.47612 16.5517 0.5 14.5756 0.5 12.1379V4.41379Z"
            fill="#4A5568"
            fillOpacity="0.4"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.0692 3.41323L10.3154 3.41323V3.90554C10.0729 3.59784 9.27839 3.19068 8.31538 3.13631C6.68058 3.044 4.5 4.12092 4.5 6.67477C4.59231 9.38246 6.99231 10.1517 8.39971 9.9204C9.80712 9.68912 10.1615 9.22862 10.1615 9.22862V9.75169C10.1615 10.244 9.63846 11.0705 8.39971 11.0705C7.16097 11.0705 6.1 10.0594 6.1 10.0594L4.93077 11.3517C4.93077 11.3517 6.1 12.732 8.39971 12.732C10.6994 12.732 12.0692 11.3517 12.0692 9.9204C12.0692 8.48911 12.0692 3.41323 12.0692 3.41323ZM8.39971 4.74748C9.17692 4.74748 10.0729 5.38246 10.0729 5.88995V7.1113C10.0729 7.78246 9.25327 8.33631 8.39971 8.33631C7.54615 8.33631 6.40769 7.844 6.40769 6.49015C6.40769 5.13631 7.6225 4.74748 8.39971 4.74748Z"
          fill="#F9F4F0"
        />
        <ellipse
          cx="12.6538"
          cy="8.75267"
          rx="0.953846"
          ry="0.953846"
          fill="#F9F4F0"
        />
      </g>
      <defs>
        <clipPath id="clip0_238_31008">
          <rect x="0.5" width="16" height="16" rx="8" fill="white" />
        </clipPath>
        <clipPath id="clip1_238_31008">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
