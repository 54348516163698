import { useApi } from "../contexts/ApiContext";
import { useConfig } from "../contexts/ConfigContext";
import { clearSession } from "../lib/utils";
import FooterIcon from "./FooterIcon";

export default function Footer({ disabled = false, showTerms = true }) {
  const { api } = useApi();
  const { config } = useConfig();

  const handleClearSession = () => {
    clearSession(api?.token);
  };

  return (
    <>
      <div className="flex flex-col items-center justify-center p-2 text-xs text-gray-400 border-t rounded-b-xl">
        {showTerms && (
          <div className="flex flex-row gap-1">
            <a href={config?.terms_of_service_url} className="underline">
              Terms
            </a>
            <a href={config?.privacy_policy_url} className="underline">
              Privacy
            </a>
            <button
              className="underline cursor-pointer"
              onClick={handleClearSession}
            >
              Restart
            </button>
          </div>
        )}
        <div className="flex items-center justify-between flex-shrink-0 gap-5 text-center">
          {disabled ? (
            <div className="flex items-center gap-1">
              <span>Powered by Grayscale</span>
              <FooterIcon className="w-4 h-4" />
            </div>
          ) : (
            <a
              href={"https://grayscaleapp.com"}
              target="_blank"
              rel="noreferrer"
              className="flex items-center gap-1"
            >
              <span>Powered by Grayscale</span>
              <FooterIcon className="w-4 h-4" />
            </a>
          )}
        </div>
      </div>
    </>
  );
}
