import clsx from "clsx";

import { useConfig } from "../contexts/ConfigContext";

export const AssistantAvatar = ({ loading, config }) => {
  const src =
    config?.assistant_avatar_url &&
    new URL(
      config.assistant_avatar_url,
      import.meta.env.APPLY_PUBLIC_STORAGE_URL,
    ).href;

  return <ChatAvatar loading={loading} className="w-10 h-10" src={src} />;
};

export const CompanyAvatar = ({ loading, config }) => {
  const src =
    config?.company_logo_url &&
    new URL(config.company_logo_url, import.meta.env.APPLY_PUBLIC_STORAGE_URL)
      .href;

  return <ChatAvatar loading={loading} className="w-8 h-8" src={src} />;
};

function ChatAvatar({ src, loading, className }) {
  const { config } = useConfig();

  // Show a loading state
  if (loading || !config) {
    <div
      className={clsx(
        className ? className : "h-10 w-10",
        "bg-gray-200 rounded-full animate-pulse",
      )}
    ></div>;
  }

  // Use the avatar URL if available
  if (src) {
    return (
      <img
        src={src}
        alt="assistant avatar"
        className={clsx(
          className ? className : "h-10 w-10",
          "rounded-full object-cover border",
        )}
      />
    );
  }

  // Fall back to using the first letter of the company name
  return (
    <div
      className={clsx(
        className ? className : "h-10 w-10",
        "flex items-center justify-center rounded-full bg-primary text-white",
      )}
    >
      <span>{config?.company_name ? config.company_name[0] : "?"}</span>
    </div>
  );
}
