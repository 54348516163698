import { createContext, useContext, useEffect, useRef, useState } from "react";

export const ApiContext = createContext();

export function ApiProvider({
  api,
  session: defaultSession,
  skipFetch = false,
  children,
}) {
  const [session, setSession] = useState(() => defaultSession || null);
  const apiRef = useRef(api);

  const fetchSession = (controller) =>
    apiRef.current
      .putSession(
        { url: document.location?.origin + document.location?.pathname },
        controller?.signal,
      )
      .then(({ data: _data, status }) => {
        setSession({
          sid: apiRef.current.sid,
          token: apiRef.current.token,
          verified: true,
          new: status === 201,
        });
      })
      .catch((err) => {
        throw err;
      });

  useEffect(() => {
    const controller = new AbortController();

    if (!skipFetch) {
      fetchSession(controller).catch((err) => {
        if (err.name !== "AbortError") throw err;
      });
    }

    return () => {
      controller.abort();
    };
  }, [skipFetch]);

  return (
    <ApiContext.Provider
      value={{
        api: apiRef.current,
        session,
        fetchSession,
      }}
    >
      {children}
    </ApiContext.Provider>
  );
}

export function useApi() {
  const context = useContext(ApiContext);
  if (context === undefined) {
    throw new Error("useApi must be used within a ApiProvider");
  }
  return context;
}
