import "@webcomponents/custom-elements";

import { ApiProvider } from "@core/contexts/ApiContext";
import { ConfigProvider } from "@core/contexts/ConfigContext";
import Api from "@core/lib/api";
import { embedded as log } from "@core/lib/logger";
import React from "react";
import { createRoot } from "react-dom/client";

import styles from "./assets/styles.css?inline";
import Embedded from "./components/Embedded";

log("startup");

const token = window._gs_apply_token;

if (!token) {
  // TODO: error handling
  throw new Error("Missing token");
}

const api = Api.fromLocalStorage(token, { deploymentContext: "embedded" });

class GSEmbedded extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({ mode: "open" });
  }
}

api
  .getConfig()
  .then(({ data, status: _status }) => {
    customElements.define("gs-embedded", GSEmbedded);

    customElements.whenDefined("gs-embedded").then(() => {
      const containerEl = document.createElement("gs-embedded");
      const sheet = new CSSStyleSheet();
      sheet.replaceSync(styles);
      containerEl.shadowRoot.adoptedStyleSheets.push(sheet);

      const appRoot = document.createElement("div");
      appRoot.id = "root";
      containerEl.shadowRoot.appendChild(appRoot);

      document.body.appendChild(containerEl);

      const reactRoot = createRoot(appRoot);
      reactRoot.render(
        <React.StrictMode>
          <ApiProvider api={api} skipFetch>
            <ConfigProvider config={data.config} styleSheet={sheet} skipFetch>
              <Embedded />
            </ConfigProvider>
          </ApiProvider>
        </React.StrictMode>,
      );
    });
  })
  .catch((err) => {
    // TODO: error handling
    throw err;
  });
