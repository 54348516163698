import clsx from "clsx";
import { forwardRef } from "react";
import { CgSpinnerAlt } from "react-icons/cg";
import { HiCheck } from "react-icons/hi";

import { useConfig } from "../contexts/ConfigContext";
import { messageComponentLabels } from "../helpers/strings";
import { AssistantAvatar } from "./Avatars";

const theme = {
  base: "flex flex-col border min-h-10 rounded-xl flex-0 w-fit transition-all duration-150",
  incoming: {
    base: "text-gray-700 bg-white origin-left",
    last: "rounded-bl-md rounded-br-2xl",
    error: "text-gray-700 bg-gray-100",
  },
  outgoing: {
    base: "text-white bg-primary origin-right",
    last: "rounded-bl-2xl rounded-br-md",
  },
};

const Message = forwardRef(function ForwardedMessage(
  {
    body,
    url,
    isError,
    incoming,
    isLast,
    isLastInGroup,
    pending,
    transitionState,
    handleRetry,
  },
  ref,
) {
  const { config } = useConfig();

  return (
    <div
      className={clsx(
        "flex flex-row items-end gap-1 w-full whitespace-pre-line",
        isLastInGroup && "mb-2",
        !incoming && "justify-end",
      )}
    >
      {/* The last message in an incoming message group should have an avatar. */}
      {incoming && (
        <div className={clsx("w-10 h-10 flex-shrink-0")}>
          {isLastInGroup && <AssistantAvatar config={config} />}
        </div>
      )}
      {!incoming && (
        <div className="flex flex-row items-end justify-end flex-shrink-0 w-10 h-10 text-gray-400">
          {pending ? (
            <CgSpinnerAlt className="w-5 h-5 animate-spin" />
          ) : (
            <HiCheck className="w-5 h-5" />
          )}
        </div>
      )}
      <div
        className={clsx(
          theme.base,
          incoming
            ? isError
              ? theme.incoming.error
              : theme.incoming.base
            : theme.outgoing.base,
          isLastInGroup
            ? incoming
              ? theme.incoming.last
              : theme.outgoing.last
            : "",
          {
            "opacity-100 scale-100":
              transitionState === "entering" || transitionState === "entered",
            "opacity-0 scale-0":
              transitionState === "exited" || transitionState === "exiting",
          },
          (ref = { ref }),
        )}
      >
        <div className={"px-3 py-2 max-w-[300px] break-words text-wrap"}>
          {body}
        </div>
        {url && (
          <div className="flex px-3 py-2 max-w-[300px] break-words border-t bg-primary text-wrap rounded-bl-md rounded-br-2xl">
            <a
              href={url.href}
              target="_blank"
              rel="noopener noreferrer"
              className="font-semibold text-white underline"
            >
              {`${url.label} →`}
            </a>
          </div>
        )}
        {isLast && isError && (
          <div className="flex px-3 py-2 max-w-[300px] break-words bg-gray-300 border-t text-wrap rounded-bl-md rounded-br-2xl">
            <button
              onClick={handleRetry}
              className="font-semibold text-gray-700 underline"
            >
              {`${messageComponentLabels.retry} ↻`}
            </button>
          </div>
        )}
      </div>
    </div>
  );
});

export default Message;
