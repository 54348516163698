import clsx from "clsx";

const buttonClsx = ({
  loading,
  disabled,
  mini,
  background,
  text,
  classNames,
} = {}) => {
  return clsx(
    {
      "pointer-events-none": loading,
      "pointer-events-none opacity-50": disabled,
      "px-3 py-1": !mini,
    },
    "relative duration-75 focus:outline-none focus:shadow-outline font-bold leading-7 rounded transition",
    "inline-flex items-center justify-center",
    background,
    text,
    classNames,
  );
};

const Button = ({ background, text, classNames, mini, children, ...rest }) => {
  const { disabled, type } = rest;
  const containerClasses = buttonClsx({
    disabled,
    mini,
    background,
    text,
    classNames,
  });

  return (
    <button
      className={containerClasses}
      disabled={disabled}
      type={type}
      {...rest}
    >
      <span>{children}</span>
    </button>
  );
};

export default Button;
