export default function useMobile() {
  const userAgent =
    navigator.userAgent || navigator.vendor || window.opera || "";

  const isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      userAgent,
    );

  return isMobile;
}
