/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import clsx from "clsx";
import { useState } from "react";
import { CgSpinnerAlt } from "react-icons/cg";
import { HiOutlineCloudUpload, HiX } from "react-icons/hi";
import { HiOutlineDocumentCheck } from "react-icons/hi2";
import { MdOutlineError } from "react-icons/md";

import { uploadBoxLabels } from "../../helpers/strings";
import useFileSizeFormat from "../../hooks/useFileSizeFormat";
import Button from "../Button/Button";

export const UploadBox = ({
  title,
  icon,
  onSubmit,
  onCancel,
  secondaryButtonText,
  primaryButtonText,
  fileSizeLimit,
  error,
  loading,
}) => {
  const [file, setFile] = useState();
  const [uploadError, setUploadError] = useState(error || null);
  const { formatFileSize } = useFileSizeFormat();

  const handleFileChange = (event) => {
    if (event.target.files[0]?.size > fileSizeLimit) {
      setUploadError({
        message:
          "Error: This file is too big. Please upload a file with a maximum size of 5MB",
      });
    }
    setFile(event.target.files[0]);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFiles = event.dataTransfer.files;
    if (droppedFiles.length > 0) {
      const newFiles = Array.from(droppedFiles);
      if (newFiles[0] > fileSizeLimit) {
        setUploadError({
          message:
            "Error: This file is too big. Please upload a file with a maximum size of 5MB",
        });
      }
      setFile(newFiles[0]);
    }
  };

  const handleRemoveFile = () => {
    setFile(undefined);
    setUploadError(null);
  };

  const handleDownloadFile = () => {
    if (!file) {
      return;
    }
    let tempLink;
    const data = new Blob([file], { type: file.type });
    const fileUrl = window.URL.createObjectURL(data);
    tempLink = document.createElement("a");
    tempLink.href = fileUrl;
    tempLink.setAttribute("download", file.name);
    tempLink.click();
  };

  return (
    <div
      onDrop={handleDrop}
      onDragOver={(event) => event.preventDefault()}
      className="flex flex-col mx-0 my-0 bg-dashed-new bg-white overflow-hidden rounded-lg p-3 border-gray-300 w-full"
    >
      <div className="flex items-center">
        <div className="flex flex-col w-full">
          <div className="mb-4 mr-4 flex items-center">
            {icon || (
              <HiOutlineCloudUpload className="h-4 w-4 text-gray-400 mr-2" />
            )}
            <h3 className="text-gray-500">{title}</h3>
          </div>
          {file && !uploadError && (
            <div className="flex items-center border border-gray-300 rounded-lg p-2 w-full">
              <HiOutlineDocumentCheck className="h-9 w-9 text-red-600 mr-2" />
              <div className="flex flex-col truncate">
                <h2 className="text-sm truncate">{file.name}</h2>
                <div className="flex text-xs">
                  <p>{formatFileSize(file.size)}</p>
                  <p className="mx-2">•</p>
                  <button
                    className="text-xs text-indigo-700 cursor-pointer"
                    onClick={handleDownloadFile}
                  >
                    {uploadBoxLabels.downloadFile}
                  </button>
                </div>
              </div>
              <HiX
                onClick={handleRemoveFile}
                className="h-4 w-4 text-gray-600 ml-auto cursor-pointer"
              />
            </div>
          )}
          {!file && !uploadError && (
            <div className="flex flex-col">
              <div className="flex items-center justify-start">
                <p className="mr-1 text-sm">{"Drag & Drop "}</p>
                <p className="text-sm mr-1">{"or "}</p>
                <input
                  type="file"
                  hidden
                  id="browse"
                  onChange={handleFileChange}
                  accept=".doc,.docx,.pdf,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                />
                <label
                  htmlFor="browse"
                  className="text-sm text-indigo-700 cursor-pointer"
                >
                  {uploadBoxLabels.browseFiles}
                </label>
              </div>
              <div>
                <p className="text-gray-400 text-xs">
                  {uploadBoxLabels.uploadHelperText}
                </p>
              </div>
            </div>
          )}
          {file && uploadError && (
            <div className="flex items-center border border-red-300 rounded-lg p-2 w-full">
              <MdOutlineError className="h-9 w-9 text-red-600 mr-2" />
              <div className="flex flex-col truncate">
                <h2 className="text-sm truncate">{file.name}</h2>
                <div className="flex text-xs">
                  <p className="text-red-600 text-xs text-wrap">
                    {uploadError.message}
                  </p>
                </div>
              </div>
              <HiX
                onClick={handleRemoveFile}
                className="h-4 w-4 text-gray-600 ml-auto cursor-pointer"
              />
            </div>
          )}
        </div>
      </div>
      <div className="my-4 border-gray-300 border-y" />
      <div className="flex px-0 py-2 items-center justify-end">
        <Button
          className="max-h-8 self-end text-xs px-4 py-1 text-gray-700 border border-gray-300 rounded-md bg-white
            flex flex-row space-x-2 items-center border-none"
          onClick={onCancel}
        >
          {secondaryButtonText || uploadBoxLabels.defaultSecondaryButtonText}
        </Button>
        <Button
          className={clsx(
            {
              "pointer-events-none opacity-50": !file || loading,
            },
            "ml-4 max-h-8 self-end text-xs px-4 py-1 text-white border rounded-md bg-red-600 hover:bg-orange-800 flex flex-row space-x-2 items-center",
          )}
          onClick={() => onSubmit(file)}
          classNames="text-lg md:ml-4"
          disabled={!file || loading}
        >
          {loading ? (
            <div className="px-2">
              <CgSpinnerAlt className="w-6 h-6 animate-spin" />
            </div>
          ) : (
            primaryButtonText || uploadBoxLabels.defaultPrimaryButtonText
          )}
        </Button>
      </div>
    </div>
  );
};
