// Temporary file with the UI strings

export const uploadBoxLabels = {
  defaultPrimaryButtonText: "Submit Resume",
  defaultSecondaryButtonText: "I don't have a resume",
  browseFiles: "Browse files",
  downloadFile: "Download file",
  uploadHelperText:
    "(Accepts PDF, DOC, DOCX, ODT, and TXT. Only one file accepted at a time.)",
};

export const messageComponentLabels = {
  uploadBoxTile: "Upload your Resume",
  retry: "Retry",
};
