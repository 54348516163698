import Color from "color";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import { useApi } from "./ApiContext";

export const ConfigContext = createContext();

const setThemeProperties = (themeColor, styleSheet) => {
  const primary = Color(themeColor);

  styleSheet.insertRule(
    `#root { --color-primary: ${primary.rgb().array().join(" ")}}`,
    0,
  );
};

export function ConfigProvider({
  config: defaultConfig,
  styleSheet,
  skipFetch = false,
  children,
}) {
  const { api } = useApi();
  const [config, setConfig] = useState(() => defaultConfig || null);

  const fetchConfig = useCallback(
    (controller) =>
      api
        .getConfig(controller?.signal)
        .then(({ data }) => {
          setConfig(data.config);
        })
        .catch((err) => {
          throw err;
        }),
    [api],
  );

  useEffect(() => {
    const controller = new AbortController();

    if (!skipFetch) {
      fetchConfig(controller).catch((err) => {
        if (err.name !== "AbortError") throw err;
      });
    }

    return () => {
      controller.abort();
    };
  }, [fetchConfig, skipFetch]);

  useEffect(() => {
    if (config) {
      // set CSS variables
      setThemeProperties(config.theme_color, styleSheet);

      // preload config images
      for (const src of ["assistant_avatar_url", "company_logo_url"]) {
        const img = new Image();
        img.src = config[src];
      }
    }
  }, [config, styleSheet]);

  return (
    <ConfigContext.Provider value={{ config, fetchConfig }}>
      {children}
    </ConfigContext.Provider>
  );
}

export function useConfig() {
  const context = useContext(ConfigContext);
  if (context === undefined) {
    throw new Error("useConfig must be used within a ConfigProvider");
  }
  return context;
}
