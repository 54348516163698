import { useRef, useState } from "react";

import Chat from "./components/Chat";
import Control from "./components/Control";
import Footer from "./components/Footer";
import Header from "./components/Header";
import { ChatProvider } from "./contexts/ChatContext";

/**
 *
 * @param {object} props
 * @param {boolean} props.close - Indicates whether the app can be closed.
 * @param {() => void} props.onClose - Callback function to handle closing the app.
 */
export default function ChatPreview({ close, onClose }) {
  const [container, setContainer] = useState(null);
  const inputRef = useRef(null);

  return (
    <>
      <div
        className="flex flex-col w-full h-full rounded-xl bg-gray-50"
        ref={setContainer}
      >
        <ChatProvider>
          <Header
            close={close}
            onClose={onClose}
            container={container}
            isDrawer={false}
            showTitle={false}
          />
          <Chat />
          <Control disabled={false} ref={inputRef} />
          <Footer showTerms={false} disabled />
        </ChatProvider>
      </div>
    </>
  );
}
